import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Location,
  CallToActionBanner,
  Call,
  Content,
  ImageCollection,
  Reviews,
  InternalLink,
} from "@bluefin/components";
import { Grid, Header, Image, span, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={2} stackable={true} className={"home-hero"}>
              <Background.Color color={"white"} textAlign={"center"}>
                <Grid
                  stackable={true}
                  verticalAlign={"middle"}
                  textAlign={"center"}
                  column={"equal"}
                >
                  <Grid.Column width={4}>
                    <Header as={"div"} className={"h6"}>
                      Welcome To
                    </Header>
                    <Image
                      size={"mini"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/5bf72e0e-0e45-45cb-9040-ed3e9a7feb51/stripes_1.png"
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <Header as={"h1"}>{fishermanBusiness.name}</Header>
                  </Grid.Column>
                </Grid>
                <Grid
                  stackable={true}
                  verticalAlign={"middle"}
                  textAlign={"center"}
                  column={"equal"}
                >
                  <Grid.Column width={8}>
                    <Location
                      phone={fishermanBusiness.primaryLocation.phoneNumber}
                      email={fishermanBusiness.primaryLocation.email}
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        city: fishermanBusiness.primaryLocation.city,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Location
                      phone={fishermanBusiness.locations[1].phoneNumber}
                      email={fishermanBusiness.locations[1].email}
                      address={{
                        street: fishermanBusiness.locations[1].street,
                        city: fishermanBusiness.locations[1].city,
                        state: fishermanBusiness.locations[1].state,
                        zip: fishermanBusiness.locations[1].zipCode,
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </Background.Color>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <CallToActionBanner stackable={true}>
            <CallToActionBanner.CTA>
              <Call
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                as={"button"}
                buttonColor={"primary"}
                label={"Egleston Sq"}
              />
            </CallToActionBanner.CTA>
            <CallToActionBanner.CTA>
              <Call
                phoneNumber={"6172983551"}
                as={"button"}
                buttonColor={"primary"}
                label={"Mattapan Sq."}
              />
            </CallToActionBanner.CTA>
          </CallToActionBanner>
          <Content stackable={true} className={"component-section-container"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>
                <Image
                  size={"small"}
                  src={
                    "https://fisherman.gumlet.io/public/5bf72e0e-0e45-45cb-9040-ed3e9a7feb51/stripes_1.png"
                  }
                />
                <span>About us</span>
              </Header>
            </Content.Markup>
            <Content.Markup truncate={true} paragraphs={3} width={12}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <ImageCollection
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "ImageCollection",
              numToSelect: 2,
            })}
            as={"gallery"}
            centered={true}
            cutUnevenRows={true}
            smartImageGrouping={false}
            disable={"none"}
            header={""}
            numberOfRowDisplayItems={2}
          />
          <div className={"reviews-container"}>
            <Header as={"h1"}>
              <Image
                size={"small"}
                src={
                  "https://fisherman.gumlet.io/public/5bf72e0e-0e45-45cb-9040-ed3e9a7feb51/stripes_1.png"
                }
              />
              <span>Customer Reviews</span>
            </Header>
            <Reviews
              reviews={fishermanBusiness.reviews.slice(0, 3)}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={""}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <Button
              to={"/about/"}
              primary={true}
              size={"large"}
              as={InternalLink}
            >
              See More
            </Button>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        phoneNumber
        email
        street
        city
        state
        zipCode
      }
      locations {
        phoneNumber
        email
        street
        city
        state
        zipCode
      }
      aboutMarkdown
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
